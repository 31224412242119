import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { UX2 } from '@wsb/guac-widget-core';
import Carousel from '@wsb/guac-widget-shared/lib/components/Carousel';
import { imageShapeRatios } from '../../../../constants/imageShapeRatios';

function PictureCarousel({
  imageList,
  imageShape,
  imageCropMethod,
  dataAids = {},
  hasImageBorder,
  carouselWidthOffset,
  ribbonComponent
}) {
  const ref = useRef(null);
  const [width, setWidth] = useState(0);
  useEffect(() => {
    setWidth(ref.current.offsetWidth);
  }, []);

  const slideWidth = hasImageBorder ? width - 2 : width;

  const shapeMultiplier =
    parseFloat(imageShapeRatios[imageShape] || imageShapeRatios.square) / 100.0;
  const slideHeight = shapeMultiplier * slideWidth;

  const borderStyles = hasImageBorder
    ? {
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: 'section'
    }
    : null;

  const wrapperStyles = {
    marginBottom: 'small',
    marginHorizontal: carouselWidthOffset || 0
  };

  const slides = imageList.map((image, index) => {
    return (
      <UX2.Element.Block key={ index } style={ borderStyles }>
        <UX2.Component.Background
          backgroundImage={ image }
          backgroundSize={
            ['shrink_to_fit', 'shrinkToFit'].includes(imageCropMethod) ? 'contain' : 'cover'
          }
          style={{ height: `${slideHeight}px`, width: `${slideWidth}px` }}
        />
      </UX2.Element.Block>
    );
  });

  return (
    <div data-aids={ dataAids.carousel } ref={ ref } style={{ position: 'relative' }}>
      { slideWidth && slideHeight && (
        <UX2.Element.Block style={ wrapperStyles }>
          <Carousel
            lazyLoad={ false }
            viewportWidth='100%'
            children={ slides }
            dots={ false }
            arrows={ false }
            draggable={ true }
            cellPadding={ 10 }
            height={ slideHeight.toString() }
            slideWidth={ slideWidth.toString() }
            infinite
          />
        </UX2.Element.Block>
      ) }
      { ribbonComponent }
    </div>
  );
}

PictureCarousel.propTypes = {
  imageList: PropTypes.array,
  imageShape: PropTypes.string,
  imageCropMethod: PropTypes.string,
  dataAids: PropTypes.object,
  hasImageBorder: PropTypes.bool,
  carouselWidthOffset: PropTypes.string,
  ribbonComponent: PropTypes.object
};

export default PictureCarousel;
